exports.shouldUpdateScroll = () => {
  window.scrollTo({
    top: 0,
    // 실제로 있는 속성이지만, typescript 에서 'auto' 혹은 'smooth' 밖에 지정되어있지 않아, ts-ignore를 사용합니다.
    // @ts-ignore
    behavior: 'instant',
  });

  return false;
};
